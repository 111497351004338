<script>
import countTo from "vue-count-to";
/**
 * Stat component
 */
export default {
  components: {
    countTo,
  },
  props: ["stats", "totalClaims", "acceptedCount", "rejectedClaimsCount", "sponsoredBusines", "payments"],
  data() {
    return {
      series: [
        {
          data: [25, 66, 41, 89, 63, 25, 44, 20, 36, 40, 54],
        },
      ],
      chartOptions: {
        fill: {
          colors: ["#5b73e8"],
        },
        chart: {
          type: "bar",
          sparkline: {
            enabled: true,
          },
        },
        plotOptions: {
          bar: {
            columnWidth: "50%",
          },
        },
        labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
        xaxis: {
          crosshairs: {
            width: 1,
          },
        },
        tooltip: {
          fixed: {
            enabled: false,
          },
          x: {
            show: false,
          },
          y: {
            title: {
              formatter: function () {
                return "";
              },
            },
          },
          marker: {
            show: false,
          },
        },
      },
      growthChartOptions: {
        fill: {
          colors: ["#f1b44c"],
        },
        chart: {
          type: "bar",
          sparkline: {
            enabled: true,
          },
        },
        plotOptions: {
          bar: {
            columnWidth: "50%",
          },
        },
        labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
        xaxis: {
          crosshairs: {
            width: 1,
          },
        },
        tooltip: {
          fixed: {
            enabled: false,
          },
          x: {
            show: false,
          },
          y: {
            title: {
              formatter: function () {
                return "";
              },
            },
          },
          marker: {
            show: false,
          },
        },
      },
      orderseries: [70],
      orderRadial: {
        fill: {
          colors: ["#EC421C"],
        },
        chart: {
          sparkline: {
            enabled: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 0,
              size: "60%",
            },
            track: {
              margin: 0,
            },
            dataLabels: {
              show: false,
            },
          },
        },
      },
      customerseries: [70],
      customerRadial: {
        fill: {
          colors: ["#EC421C"],
        },
        chart: {
          sparkline: {
            enabled: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 0,
              size: "60%",
            },
            track: {
              margin: 0,
            },
            dataLabels: {
              show: false,
            },
          },
        },
      },
    };
  },
};
</script>

<template>
  <div class="row">
    <div class="col-md-6 col-xl-4">
      <div class="card">
        <div class="card-body">
          <!-- <div class="float-end mt-2">
            <apexchart class="apex-charts" dir="ltr" width="70" height="40" :options="chartOptions" :series="series">
            </apexchart>
          </div> -->
          <div>
            <h4 class="mb-1 mt-1 header-title">
              <span data-plugin="counterup">
                <countTo :startVal="1000" :endVal="Number(stats.users)" :duration="2000"></countTo>
              </span>
            </h4>
            <p class=" mb-0 gray-text">Total Customers</p>
          </div>
          <!-- <p class="text-muted mt-3 mb-0">
            <span class="text-success me-1">
              <i class="mdi mdi-arrow-up-bold me-1"></i>2.65%
            </span>
            since last week
          </p> -->
        </div>
      </div>
    </div>
    <div class="col-md-6 col-xl-4">
      <div class="card">
        <div class="card-body">
          <div class="float-end mt-2">
            <apexchart class="apex-charts" type="radialBar" dir="ltr" width="45" height="45" :options="orderRadial"
              :series="orderseries"></apexchart>
          </div>
          <div>
            <h4 class="mb-1 mt-1 header-title">
              <span data-plugin="counterup">
                <countTo :startVal="1000" :endVal="Number(stats.businesses)" :duration="2000"></countTo>
              </span>
            </h4>
            <p class="gray-text mb-0">Total Restaurants</p>
          </div>
          <!-- <p class="text-muted mt-3 mb-0">
            <span class="text-danger me-1">
              <i class="mdi mdi-arrow-down-bold me-1"></i>0.82%
            </span>
            since last week
          </p> -->
        </div>
      </div>
    </div>
    <!-- end col-->

    <!-- <div class="col-md-6 col-xl-3">
      <div class="card">
        <div class="card-body">
          <div class="float-end mt-2">
            <apexchart class="apex-charts" type="radialBar" dir="ltr" width="45" height="45" :options="customerRadial"
              :series="customerseries"></apexchart>
          </div>
          <div>
            <h4 class="mb-1 mt-1 header-title">
              <span data-plugin="counterup">
                <countTo :startVal="1000" :endVal="stats.activeBusiness" :duration="2000"></countTo>
              </span>
            </h4>
            <p class="gray-text mb-0">Active Businesses</p>
          </div>
          <p class="text-muted mt-3 mb-0">
            <span class="text-danger me-1">
              <i class="mdi mdi-arrow-down-bold me-1"></i>6.24%
            </span>
            since last week
          </p>
        </div>
      </div>
    </div> -->
    <!-- <div class="col-md-6 col-xl-3">
      <div class="card">
        <div class="card-body">
          <div class="float-end mt-2">
            <apexchart class="apex-charts" dir="ltr" width="70" height="40" :options="growthChartOptions"
              :series="series"></apexchart>
          </div>
          <div>
            <h4 class="mb-1 mt-1 header-title">
              <span data-plugin="counterup">
                <countTo :startVal="1000" :endVal="totalClaims" :duration="2000"></countTo>
              </span>
            </h4>
            <p class="gray-text mb-0">Total Claims</p>
          </div>
          <p class="text-muted mt-3 mb-0">
            <span class="text-success me-1">
              <i class="mdi mdi-arrow-up-bold me-1"></i>10.51%
            </span>
            since last week
          </p>
        </div>
      </div>
    </div> -->
    <!-- end col-->
    <!-- <div class="col-md-6 col-xl-3">
      <div class="card">
        <div class="card-body">
          <div class="float-end mt-2">
            <apexchart class="apex-charts" type="radialBar" dir="ltr" width="45" height="45" :options="customerRadial"
              :series="customerseries"></apexchart>
          </div>
          <div>
            <h4 class="mb-1 mt-1 header-title">
              <span data-plugin="counterup">
                <countTo :startVal="1000" :endVal="acceptedCount" :duration="2000"></countTo>
              </span>
            </h4>
            <p class="gray-text mb-0">Accepted Claims</p>
          </div>
          <p class="text-muted mt-3 mb-0">
            <span class="text-danger me-1">
              <i class="mdi mdi-arrow-down-bold me-1"></i>6.24%
            </span>
            since last week
          </p>
        </div>
      </div>
    </div> -->
    <!-- end col-->
    <!-- <div class="col-md-6 col-xl-3">
      <div class="card">
        <div class="card-body">
          <div class="float-end mt-2">
            <apexchart class="apex-charts" type="radialBar" dir="ltr" width="45" height="45" :options="customerRadial"
              :series="customerseries"></apexchart>
          </div>
          <div>
            <h4 class="mb-1 mt-1 header-title">
              <span data-plugin="counterup">
                <countTo :startVal="1000" :endVal="rejectedClaimsCount" :duration="2000"></countTo>
              </span>
            </h4>
            <p class="gray-text mb-0">Rejected Claims</p>
          </div>
          <p class="text-muted mt-3 mb-0">
            <span class="text-danger me-1">
              <i class="mdi mdi-arrow-down-bold me-1"></i>6.24%
            </span>
            since last week
          </p>
        </div>
      </div>
    </div> -->
    <!-- end col-->
    <!-- <div class="col-md-6 col-xl-3">
      <div class="card">
        <div class="card-body">
          <div class="float-end mt-2">
            <apexchart class="apex-charts" type="radialBar" dir="ltr" width="45" height="45" :options="customerRadial"
              :series="customerseries"></apexchart>
          </div>
          <div>
            <h4 class="mb-1 mt-1 header-title">
              <span data-plugin="counterup">
                <countTo :startVal="1000" :endVal="Number(stats.members)" :duration="2000"></countTo>
              </span>
            </h4>
            <p class="gray-text mb-0">Sponsored Businesses</p>
          </div>
           <p class="text-muted mt-3 mb-0">
            <span class="text-danger me-1">
              <i class="mdi mdi-arrow-down-bold me-1"></i>6.24%
            </span>
            since last week
          </p> 
        </div>
      </div>
    </div> -->
    <!-- end row-->
    <div class="col-md-6 col-xl-4">
      <div class="card">
        <div class="card-body">
          <div class="float-end mt-2">
            <apexchart class="apex-charts" type="radialBar" dir="ltr" width="45" height="45" :options="customerRadial"
              :series="customerseries"></apexchart>
          </div>
          <div>
            <h4 class="mb-1 mt-1 header-title">
              <span data-plugin="counterup">
                <countTo :startVal="1000" :endVal="Number(stats.subscriptions)" :duration="2000"></countTo>
              </span>
            </h4>
            <p class="gray-text mb-0">Total Subscriptions</p>
          </div>
          <!-- <p class="text-muted mt-3 mb-0">
            <span class="text-danger me-1">
              <i class="mdi mdi-arrow-down-bold me-1"></i>6.24%
            </span>
            since last week
          </p> -->
        </div>
      </div>
    </div>
  </div>
  <!-- end row-->
</template>
